import { translate as t } from '@/util/i18n';

export const isRequired = (name, value) =>
  !!value ||
  t('isRequired', null, {
    value: t(name),
  });

export const minimum = (name, value, characters) =>
  value.length >= characters ||
  t('mustBeMinimum', null, {
    value: t(name),
    characters,
  });

export const maximum = (name, value, characters) =>
  value.length <= characters ||
  t('mustBeMaximum', null, {
    value: t(name),
    characters,
  });

export const isValid = (name, value, valid) =>
  valid ||
  t('isInvalid', null, {
    value: t(name),
  });
