<template>
  <div>
    <v-autocomplete
      v-model="country"
      :items="countries"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :label="label"
      :hint="hint"
      persistent-hint
      :prepend-icon="prependIcon"
      :placeholder="$t('searchCountryPlaceholder')"
      @input="onChange"
    >
      <template #selection="{ item }">
        <v-chip>
          <v-img
            :src="safeGetFlagFile(item.value)"
            style="max-height: 30px"
            class="c-flag icon mr-2"
          />
          {{ item.text }} (+{{ item.phone }})
        </v-chip>
      </template>

      <template #item="{ item }">
        <v-list-item-icon>
          <v-img :src="safeGetFlagFile(item.value)" style="max-height: 30px" class="c-flag icon" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
          <v-list-item-subtitle> (+{{ item.phone }}) </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import countryPhoneNumbers from '@/lib/ui/countryPhoneNumbers.json';
import { warnLog } from '@/lib/util/logger';

const countries = countryPhoneNumbers.map((country) => ({
  value: country.id,
  text: country.name,
  phone: country.phone,
}));

export default {
  name: 'CountrySelector',

  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
      default: () => [],
    },

    hint: {
      type: String,
      required: false,
      default: null,
    },

    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },

    prependIcon: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    country: '',
    countries,
  }),

  watch: {
    value() {
      this.country = this.value;
    },
  },

  created() {
    this.country = this.value;
  },

  methods: {
    onChange() {
      this.$emit('input', this.country);
    },

    safeGetFlagFile(country) {
      try {
        return require(`@/assets/images/flags/${country.toLowerCase()}.svg`);
      } catch (error) {
        warnLog(error);
        return null;
      }
    },
  },
};
</script>
