<template>
  <v-btn small fab color="primary" @click="togglePlay">
    <div v-if="playing" key="playing"><v-icon class="pa-1">fa-fw fa-stop</v-icon></div>
    <div v-else key="notPlaying"><v-icon class="pa-1">fa-fw fa-play</v-icon></div>
  </v-btn>
</template>

<script>
import { on } from '@/lib/ui/dom';

const allAudio = [];

export default {
  name: 'AudioPlayButton',

  props: {
    src: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    audio: null,
    playing: false,
  }),

  created() {
    this.audio = new Audio(this.src);
    this.audio.volume = 0.3;
    this.audio.autoplay = false;
    allAudio.push(this.audio);

    on(this.audio, ['ended', 'pause'], () => {
      this.audio.load();
      this.playing = false;
    });
  },

  beforeDestroy() {
    this.audio.pause();
  },

  methods: {
    togglePlay() {
      this.playing = !this.playing;

      if (this.playing) {
        allAudio.forEach((otherAudio) => {
          otherAudio.pause();
        });
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
  },
};
</script>

<style></style>
