<template>
  <div>
    <table width="100%">
      <thead>
        <tr>
          <th align="left">{{ $t('alertType') }}</th>
          <th align="left">{{ $t('file') }}</th>
          <th align="left">{{ $t('change') }}</th>
          <th align="right">{{ $t('preview') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in alertSoundFiles" :key="index">
          <td align="left" class="pa-1">
            <v-avatar size="40" class="pa-1 mr-1" :color="getAlertTypeColor(item.type)">
              <v-img contain :src="require(`@/assets/images/alert-types/${item.type}.svg`)" />
            </v-avatar>
            {{ getAlertTypeDisplayName(item.type) }}
          </td>
          <td align="left">
            {{ item.sound }}
          </td>
          <td align="left">
            <v-btn @click="openSetDialog(item)">{{ $t('change') }}</v-btn>
          </td>
          <td align="right">
            <audio-play-button :src="require(`@/assets/sounds/${item.sound}.mp3`)" />
          </td>
        </tr>
      </tbody>
    </table>
    <v-dialog v-model="showSetDialog" max-width="500" @click:outside="closeSetDialog">
      <v-card>
        <v-card-title>
          {{
            $t('selectSoundFileFor', null, { alertType: getAlertTypeDisplayName(alertTypeToEdit) })
          }}
        </v-card-title>
        <v-card-text>
          <v-list max-height="400" style="overflow-y: auto">
            <v-list-item-group
              v-model="selectedSoundFileIndex"
              mandatory
              @change="soundFileIsUnsaved = true"
            >
              <v-list-item v-for="(item, index) in availableSoundFiles" :key="index">
                <v-list-item-content>
                  <div>
                    <div v-if="index === selectedSoundFileIndex" :key="item + 'selected'">
                      <v-icon>far fa-dot-circle</v-icon>
                    </div>
                    <div v-else :key="item + 'notSelected'">
                      <v-icon>far fa-circle</v-icon>
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <audio-play-button :src="require(`@/assets/sounds/${item}.mp3`)" />
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" @click="closeSetDialog">{{ $t('cancel') }}</v-btn>
          <v-btn :loading="loading" color="primary" :disabled="!soundFileIsUnsaved" @click="set">{{
            $t('save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AudioPlayButton from '@/components/AudioPlayButton';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';

const soundFileSettingPostfix = 'SoundFile';

const availableSoundFiles = [
  'alarm_clock',
  'bell',
  'choir',
  'droplet',
  'fire',
  'gong',
  'sinus',
  'siren1',
  'siren2',
  'siren3',
  'sonar',
  'sos',
  'warning',
];

const defaultAlertSoundFiles = {
  alert: 'bell',
  alarm: 'sinus',
  missionAlarm: 'siren1',
  sos: 'sinus',
  position: 'gong',
  comment: 'droplet',
  update: 'gong',
};

export default {
  name: 'AlertSoundFileSettings',

  components: {
    AudioPlayButton,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    alertSoundFiles: [],
    availableSoundFiles,
    alertTypeToEdit: null,
    showSetDialog: false,
    selectedSoundFileIndex: -1,
    soundFileIsUnsaved: false,
  }),

  computed: {
    selectedSoundFile() {
      return availableSoundFiles[this.selectedSoundFileIndex];
    },
  },

  watch: {
    settings() {
      this.setAlertSoundFilesFromSettings();
    },
  },

  created() {
    this.setAlertSoundFilesFromSettings();
  },

  methods: {
    setAlertSoundFilesFromSettings() {
      this.alertSoundFiles = [];
      Object.keys(defaultAlertSoundFiles).forEach((alertType) => {
        const defaultSoundFile = defaultAlertSoundFiles[alertType];
        const soundFileFromSettings = this.settings[alertType + soundFileSettingPostfix];
        const soundFile = soundFileFromSettings || defaultSoundFile;
        this.alertSoundFiles.push({
          type: alertType,
          sound: soundFile,
        });
      });
    },

    openSetDialog(item) {
      this.alertTypeToEdit = item.type;
      this.selectedSoundFileIndex = availableSoundFiles.indexOf(item.sound);
      this.showSetDialog = true;
    },

    closeSetDialog() {
      this.showSetDialog = false;
      this.soundFileIsUnsaved = false;
    },

    async set() {
      this.loading = true;
      const key = this.alertTypeToEdit + soundFileSettingPostfix;
      const value = this.selectedSoundFile;
      try {
        await api.setCustomerSetting(key, value);

        this.$emit('set');
      } catch (error) {
        errorMessage(
          this.$t('saveCustomerSettingError'),
          this.$t('saveCustomerSettingErrorMsg'),
          error,
        );
      }

      this.closeSetDialog();
      this.loading = false;
    },

    getAlertTypeColor(alertType) {
      return (
        {
          alert: 'yellowPrimary',
          alarm: 'redPrimary',
          missionAlarm: 'redPrimary',
          sos: 'redPrimary',
          position: 'blueDark',
          comment: 'blueDark',
          update: 'blueDark',
        }[alertType] || 'blueDark'
      );
    },

    getAlertTypeDisplayName(alertType) {
      return (
        {
          alert: this.$t('alerts'),
          alarm: this.$t('alarms'),
          missionAlarm: this.$t('missionAlarms'),
          sos: this.$t('sos'),
          position: this.$t('positionings'),
          comment: this.$t('comments'),
          update: this.$t('alertUpdates'),
        }[alertType] || alertType
      );
    },
  },
};
</script>
