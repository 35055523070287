<template>
  <div>
    <div v-if="isDeletingCompany" class="d-flex justify-center mt-16">
      <v-progress-circular indeterminate />
    </div>

    <v-container v-else class="pa-sm-6 pa-lg-16">
      <v-row>
        <v-col cols="12" sm="6">
          <v-card height="100%" class="d-flex flex-column">
            <v-card-title>{{ $t('companyName') }}</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="companyName"
                :label="$t('companyName')"
                :hint="$t('companyNameHelperText')"
                :rules="companyNameRules"
                @input="companyNameIsUnsaved = true"
              />
            </v-card-text>
            <v-spacer />
            <v-card-actions class="mt-auto">
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading || loadingCompanyName"
                :disabled="!companyName || !companyNameIsUnsaved"
                @click="saveCompanyName"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card height="100%" class="d-flex flex-column">
            <v-card-title>{{ $t('country') }}</v-card-title>
            <v-card-text>
              <country-selector
                v-model="customerCountry"
                :label="$t('country')"
                :hint="$t('customerCountryHelperText')"
                @input="customerCountryIsUnsaved = true"
              />
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <div v-if="!customerCountry && noCountrySet && suggestedCountry" class="ml-2">
                {{ $t('setCountryTo') }}
                <v-btn small class="mx-1" @click="setSuggestedCountry">
                  <img
                    :src="
                      require(`@/assets/images/flags/${suggestedCountry.value.toLowerCase()}.svg`)
                    "
                    height="20px"
                    class="ml-n2 mr-2"
                  />
                  {{ suggestedCountry.text }}
                </v-btn>
                ?
              </div>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading || loadingCustomerCountry"
                :disabled="!customerCountry || !customerCountryIsUnsaved"
                @click="saveCustomerCountry"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-card height="100%" class="d-flex flex-column">
            <v-card-title>{{ $t('defaultSosMsg') }}</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="emergencyText"
                :label="$t('message')"
                :messages="$t('defaultSosMsgHelperText')"
                @input="emergencyTextIsUnsaved = true"
              />
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading || loadingEmergencyText"
                :disabled="!emergencyText || !emergencyTextIsUnsaved"
                @click="saveEmergencyText"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card height="100%" class="d-flex flex-column">
            <v-card-title>{{ $t('companyAlertSound') }}</v-card-title>
            <v-card-text>
              <v-select
                v-model="alertSound"
                :items="alertSoundOptions"
                :label="$t('mode')"
                :messages="$t('companyAlertSoundHelperText')"
                @input="alertSoundIsUnsaved = true"
              />
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading || loadingAlertSound"
                :disabled="!alertSoundIsUnsaved"
                @click="saveAlertSound"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t('alertSoundFiles') }}</v-card-title>
            <v-card-text>
              <p v-html="$t('alertSoundFilesAndroidInfo')" />
              <alert-sound-file-settings :settings="settings" @set="loadSettings" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t('deleteCompany') }}</v-card-title>
            <v-card-text class="mt-auto">
              <p v-html="$t('deleteCompanyText')" />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading || loadingCompanyName"
                @click="promptDeleteCompany"
              >
                {{ $t('delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from '@/util/api.js';
import auth from '@/util/auth';
import errorMessage from '@/util/errorMessage.js';
import confirmDialog from '@/components/dialog/confirmDialog';
import CountrySelector from '@/components/CountrySelector';
import AlertSoundFileSettings from './AlertSoundFileSettings.vue';
import countryPhoneNumbers from '@/lib/ui/countryPhoneNumbers.json';
import { isRequired, maximum, minimum } from '@/consts/inputRules';

const countries = countryPhoneNumbers.map((country) => ({
  value: country.id,
  text: country.name,
}));

const companyNameRules = [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 50),
];

export default {
  name: 'Settings',

  components: {
    CountrySelector,
    AlertSoundFileSettings,
  },

  data: (self) => ({
    loading: false,
    isDeletingCompany: false,
    settings: {},

    loadingCompanyName: false,
    companyName: '',
    companyNameIsUnsaved: false,
    companyNameRules,

    loadingCustomerCountry: false,
    noCountrySet: false,
    suggestedCountry: '',
    customerCountry: '',
    customerCountryIsUnsaved: false,

    loadingEmergencyText: false,
    emergencyText: '',
    emergencyTextIsUnsaved: false,

    loadingAlertSound: false,
    alertSound: 'user',
    alertSoundIsUnsaved: false,
    alertSoundOptions: [
      {
        value: 'user',
        text: self.$t('usersDecide'),
      },
      {
        value: 'on',
        text: self.$t('alwaysOn'),
      },
      {
        value: 'off',
        text: self.$t('alwaysOff'),
      },
    ],
  }),

  created() {
    this.loadCompanyName();
    this.loadSettings();
    this.loadCurrentCountryFromIP();
  },

  methods: {
    async loadCompanyName() {
      this.loadingCompanyName = true;
      try {
        const id = parseInt(localStorage.getItem('customerId'), 10);
        this.companyName = await api.getCustomerName(id);
      } catch (error) {
        errorMessage(this.$t('loadCustomerNameError'), this.$t('loadCustomerNameErrorMsg'), error);
      }
      this.loadingCompanyName = false;
    },

    async loadSettings() {
      this.loading = true;
      try {
        const settings = await api.getCustomerSettings();
        this.settings = settings;
        if (settings.customerCountry) {
          this.customerCountry = settings.customerCountry;
        } else {
          this.noCountrySet = true;
        }
        this.emergencyText = settings.emergencyText ?? '';
        this.alertSound = settings.alertSound ?? 'user';
      } catch (error) {
        errorMessage(
          this.$t('loadCustomerSettingsError'),
          this.$t('loadCustomerSettingsErrorMsg'),
          error,
        );
      }
      this.loading = false;
    },

    async loadCurrentCountryFromIP() {
      try {
        const res = await api.getGeoAddress();
        const countryFromIP = res.country.toUpperCase();
        const country = countries.find((country) => country.value.toUpperCase() === countryFromIP);

        if (country) {
          this.suggestedCountry = country;
        }
      } catch (error) {
        // Do nothing
      }
    },

    async saveSetting(key, value) {
      try {
        await api.setCustomerSetting(key, value);
      } catch (error) {
        this.displaySaveSettingErrorMessage(error);
      }
    },

    async setSuggestedCountry() {
      this.customerCountry = this.suggestedCountry.value;
      await this.saveCustomerCountry();
      this.noCountrySet = false;
    },

    async saveCustomerCountry() {
      this.loadingCustomerCountry = true;
      await this.saveSetting('customerCountry', this.customerCountry);
      this.customerCountryIsUnsaved = false;
      this.loadingCustomerCountry = false;
    },

    async saveEmergencyText() {
      this.loadingEmergencyText = true;
      await this.saveSetting('emergencyText', this.emergencyText);
      this.emergencyTextIsUnsaved = false;
      this.loadingEmergencyText = false;
    },

    async saveAlertSound() {
      this.loadingAlertSound = true;
      await this.saveSetting('alertSound', this.alertSound);
      this.alertSoundIsUnsaved = false;
      this.loadingAlertSound = false;
    },

    async saveCompanyName() {
      try {
        this.loadingCompanyName = true;
        await api.setCustomerName(this.companyName);
        this.loadingCompanyName = false;
        this.companyNameIsUnsaved = false;
        this.$root.$emit('companyNameUpdated');
      } catch (error) {
        errorMessage(this.$t('saveCustomerNameError'), this.$t('saveCustomerNameErrorMsg'), error);
      }
    },

    async promptDeleteCompany() {
      const confirmed = await confirmDialog(
        this.$t('deleteCompany'),
        this.$t('deleteCompanyTextShort', null, {
          companyName: this.companyName,
        }),
        this.$t('cancel'),
        this.$t('delete'),
        null,
        { text: this.$t('deleteCompanyConfirmation'), value: this.companyName },
      ).open();

      if (confirmed) {
        try {
          this.isDeletingCompany = true;
          await api.deleteCustomer();
          this.isDeletingCompany = false;
          auth.logout();
        } catch (error) {
          this.isDeletingCompany = false;
          errorMessage(this.$t('deleteCompanyError'), this.$t('deleteCompanyErrorMsg'), error);
        }
      }
    },

    displaySaveSettingErrorMessage(error) {
      errorMessage(
        this.$t('saveCustomerSettingError'),
        this.$t('saveCustomerSettingErrorMsg'),
        error,
      );
    },
  },
};
</script>
